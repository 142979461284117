import React from 'react';
import tw from 'tailwind.macro'
import styled from 'styled-components'
import theme from '../../config/theme'

import ImageURL from '../images/devices.png'

const Wrapper = styled.section`
  ${tw`mb-20 md:mb-0`}
  min-height: 100vh;
  display: flex;
`

const Inner = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: auto;
`

const Column = styled.div`
  flex: 0 50%;
  &:first-child {
    text-align: right;
  }
  @media (max-width: ${theme.breakpoints[1]}) {
    flex: 0 100%;
    &:first-child {
      text-align: center;
    }
  }
`

const Image = styled.img`
  max-width: 80%;
  text-align: right;
`

export default ({children}) => (
  <Wrapper>
    <Inner className="inner">
      <Column>
        <Image src={ImageURL} alt="Dispositivos"/>
      </Column>
      <Column className="inner">
        {children}
      </Column>
    </Inner>
  </Wrapper>
)