import React from 'react'
import styled from 'styled-components'
import theme from '../../config/theme'

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  ::before,
  ::after {
    content: '';
    position: absolute;
    background-color: ${theme.colors.secondary};
    z-index: -1;
  }
  ::before {
    top: 15%;
    left: 50%;
    width: 200vw;
    height: 200vw;
    border-radius: 100%;
    transform: translateX(-50%);
    @media (max-width: ${theme.breakpoints[1]}) {
      top: 10%;
      border-radius: 0;
      height: 200vh;
    }
  }
  ::after {
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
  }
`

const Content = styled.div`
  text-align: center;
`

export default ({children}) => (
  <Wrapper>
    <div className="inner">
      <Content>
        {children}
      </Content>
    </div>
  </Wrapper>
)