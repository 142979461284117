import React from "react"
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import theme from '../../config/theme'

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from '../components/hero'
import { BigTitle, Title, TitleLight, SiteTitle } from '../components/titles'
import Button from '../components/buttons'
import Funcionalidad from '../components/funcionalidad'
import Planes from '../components/planes'
import Demo from '../components/demo'
import SVG from '../components/svg'

import PlanCard from '../components/plan-card'

const PlanGrid = styled.div`
  ${tw`pt-5 mb-20 md:pt-20`}
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  max-width: 50rem;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: ${theme.breakpoints[1]}) {
    grid-template-columns: 1fr;
  }
`

const Banner = styled.div`
  ${tw`p-5 mx-0 md:p-12 md:mx-32 mt-20`};
  display: flex;
  border-radius: 3rem;
  background-color: ${theme.colors.primary};
  color: white;
  position: relative;
  flex-direction: column;
  align-items: center;
  box-shadow: #441b5b 0 2rem 0;
  overflow: hidden; 
  svg {
    margin: 3rem 0;
  }
  @media (max-width: ${theme.breakpoints[1]}) {
    
    svg {
      width: 100%;
      &:not(:first-child) {
        display: none;
      }
    }
  }
`

const BannerContent = styled.div`
  ${tw`pl-0 text-center md:pl-10`}
`

const IndexPage = props => (
  <Layout location={props.location}>
    <SEO title="Sistema de registre horari de treballadors" description="Aplicació per al registre de jornada laboral que compleix amb la normativa legal espanyola vigent des de maig de 2019"/>
    <Hero>
      <SiteTitle>Jornada</SiteTitle>
      <BigTitle>Sistema de registre horari</BigTitle>
      <p>Registra l'horari dels teus treballadors amb targetes o clauers contactless, mòbil i/o empremta dactilar.</p>
      <Button
        to="/ca/registre"
        invert
        onClick={() => {
          trackCustomEvent({
            category: "Landing",
            action: "empieza_hero_btn",
          })
        }}
      >Comença amb el teu espai gratuïtament</Button>
    </Hero>
    <Funcionalidad>
      <Title>Monitorització<br/>Panell de control</Title>
      <ul>
        <li>Visualització web i mòbil.</li>
        <li>Generació d'informes mensuals.</li>
        <li>Dies de baixa, vacances o personals.</li>
        <li>Còpies de seguretat durant 5 anys.</li>
      </ul>
    </Funcionalidad>
    <Planes>
      <Title>Plans i serveis</Title>
      <PlanGrid>
        <PlanCard
          title="Micro"
          price="5"
          priceMeta="al mes per treballador"
          content="Fins a tres treballadors"
          month="true"
        />
        <PlanCard
          title="Micro Plus"
          price="15" 
          content="De 4 a 10 treballadors"
          featured
          month="true"
        />
        <PlanCard
          title="PYME"
          price="25" 
          content="De 10 a 25 treballadors"
          month="true"
        />
        <PlanCard
          title="PYME Plus"
          content="Más de 25 treballadors"
          fullGrid
          featured
          month="true"
        />
      </PlanGrid>
      <TitleLight>Comença amb el teu pla gratuïtament, afegeix treballadors, augmenta progressivament i paga a mesura que generis registres.</TitleLight>
      <Banner>
        <SVG icon="contactless" width="40" fixed/>
        <BannerContent>
          <Title mb="0.5">Caixa contactless (opcional)</Title>
          <h4>Dispositiu lector contactless + Clauers + Targetes</h4>
          <p>El dispositiu és una caixa que va connectada al corrent i ja ve configurada amb la seva connexió WIFI per transmetre les dades.</p>
               <p>Pot tenir més d'una caixa, depenent dels seus despatxos o estades, i cada treballador pot tenir un clauer o una targeta contactless.</p>
          {/* <Button to="/contactless" invert>Más información</Button> */ }
          <Title>150,00€/caixa</Title>
          <p><small>(Opcional) Si no desitja el dispositiu, els seus treballadors podran iniciar i parar la jornada amb el mòbil o amb el seu ordinador.</small></p>
        </BannerContent>
      </Banner>
    </Planes>
    <Demo>
      <Title>Prova Jornada10 ara de manera totalment gratuïta.</Title>
      <p>Entra i remena tot el que vulguis sense compromís. Afegeix treballadors, mira el calendari d'hores, genera informes i molt més</p>
      <Button
        to="/ca/demostracio"
        invert
        onClick={() => {
          trackCustomEvent({
            category: "Landing",
            action: "demostracion_seccio_btn",
          })
        }}
      >Prova la demostració</Button>
    </Demo>
  </Layout>
)

export default IndexPage