import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import theme from '../../config/theme'

import Logo from './logo'

const Wrapper = styled.section`
  ${tw`py-32`}
  background-color: ${theme.colors.secondary};
`

const Box = styled.div`
  ${tw`py-10 px-10 md:px-40`}
  border: .1rem solid ${theme.colors.primary};
  max-width: 50rem;
  margin-left: auto;
  margin-right: auto;
  border-radius: 1rem;
  text-align: center;
  a {
    display: inline-block;
    margin-bottom: 2rem;
  }
`

export default ({children}) => (
  <Wrapper>
    <div className="inner">
      <Box>
        <Logo invert/>
        {children}
      </Box>
    </div>
  </Wrapper>
)