import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import theme from '../../config/theme'

import Button from './buttons'


const BlockWrapper = styled.div`
  border-radius: 2rem;
  padding: 3rem 1rem;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.featured ? theme.colors.primary : 'white'};
  color: ${props => props.featured ? 'white' : 'inherit'};
  text-align: center;
  box-shadow: 0 0 2em 0 rgba(0,0,0,.1);
  ${props => props.featured ? `
    margin: -2rem 0;
    padding: 4rem 1rem;
    font-size: 1.2rem;
    @media (max-width: ${theme.breakpoints[1]}) {
      margin: 0;
    }
  ` : null}
  ${props => props.fullGrid ? `
    grid-column-start: 1;
    grid-column-end: 4;
    text-align: left;
    margin-top: 4rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
    flex-direction: row;
    align-items: center;
    ${BlockTitle} {
      margin-bottom: 1rem;
    }
    ${BlockContent} {
      margin-bottom: 0rem;
    }
    @media (max-width: ${theme.breakpoints[1]}) {
      grid-column-start: unset;
      grid-column-end: unset;
      flex-direction: column;
      ${BlockContent} {
        margin-bottom: 3rem;
      }
    }
  ` : null}
`

const BlockText = styled.div`
  display flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 100%;
  padding: 0 2rem;
`

const BlockTitle = styled.div`
  font-weight: bold;
  margin-bottom: 1rem;
  font-size: 1.5em;
`

const BlockContent = styled.div`
  font-size: 1rem;
  margin-bottom: 2rem;
`

const BlockPrice = styled.div`
  font-weight: bold;
  margin-bottom: .5rem;
  font-size: 3em;
  line-height: 1;
  &:after {
    content: '€';
    font-size: 2rem;
  } 
`

const BlockMeta = styled.div`
  font-weight: bold;
  color: lightgray;
  margin-bottom: .5rem;
  font-size: .8em;
`

const PriceCard = ({ title, price, priceMeta, content, featured, fullGrid, month }) => (
  <BlockWrapper featured={featured} fullGrid={fullGrid}>
    <BlockText>
      {price && (
        <BlockPrice price={price} month={month}>{price}</BlockPrice>
      )}
      {priceMeta && (
        <BlockMeta>{priceMeta}</BlockMeta>
      )}
      <BlockTitle>{title}</BlockTitle>
      <BlockContent>{content}</BlockContent>
    </BlockText>
    {featured ? ( 
      <Button to={`/registro`}>Seleccionar</Button> 
    ) : (
      <Button to={`/registro`} invert>Seleccionar</Button> 
    )}
    
  </BlockWrapper> 
)

PriceCard.propTypes = {
  title: PropTypes.string,
  price: PropTypes.string,
  priceMeta: PropTypes.string,
  content: PropTypes.string,
}

PriceCard.defaultProps = {
  title: ``,
  price: ``,
  priceMeta: ``,  
  content: ``,
}

export default PriceCard