import React from 'react';
import tw from 'tailwind.macro'
import theme from '../../config/theme'
import styled from 'styled-components'

const Wrapper = styled.section`
  position: relative;
  height: 120vh;
`

const Inner = styled.div`
  ${tw`px-2 pt-2 md:pt-0 md:px-6 lg:px-12`};
  max-width: ${theme.container};
  margin-left: auto;
  margin-right: auto;
  p {
    max-width: 30rem;
  }
`

const Hero = ({children}) => (
  <Wrapper>
    <Inner>
      {children}
    </Inner>
  </Wrapper>
)

export default Hero